<template>
  <div>
    <div class="pt-1 px-6 pb-6">
      <hb-data-table-header>
        <template v-slot:description>
          Setup your Accounting Templates.
        </template>
        <template v-slot:actions>
          <hb-btn color="secondary" small @click="addNewTemplate">Add New Template</hb-btn>
        </template>
      </hb-data-table-header>
      <hb-data-table
          :headers="headers"
          :items="accountingTemplates"
          disable-pagination
          hide-default-footer
          @click:row="editItem"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name | capitalize }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.AccountingSetup.description | capitalize }}
          </template>
          <template v-slot:item.properties="{ item }">
            {{ item.Properties.length ? item.Properties.length : "0" }}
            Properties
          </template>
          <template v-slot:item.default="{ item }">
            <span v-if="item.is_default">
              <hb-icon
                class="pr-2"
                mdi-code="mdi-table-actions-enable"
              ></hb-icon>
            </span>
            <span v-else>
              <hb-icon
                class="pr-2"
                mdi-code="mdi-table-actions-disable"
              ></hb-icon>
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <hb-menu options align-right>
              <v-list>
                <v-list-item @click="editItem(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <span v-if="!item.is_default">
                  <v-list-item @click="confirmDeleteItem(item)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </span>
                <v-list-item @click="duplicateItem(item)">
                  <v-list-item-title>Duplicate</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>
        </hb-data-table>
    </div>
    <hb-modal
      title="Delete Template"
      confirmation
      size="medium"
      v-if="dialog"
      v-model="dialog"
      @close="$emit('close')"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          Are you sure you want to permanently delete this template?
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn primary @click="deleteItem">Delete Template</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import { EventBus } from "../../../EventBus.js";
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
export default {
  name: "SetupAccountingTemplates",
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Book Type", value: "type" },
        { text: "Properties", value: "properties" },
        { text: "Default", value: "default" },
        {
          text: "",
          value: "actions",
          align: "right",
          sortable: false,
          width: 10,
        },
      ],
      selectedItem: {},
      dialog: false,
    };
  },
  mixins: [notificationMixin],
  created() {
    this.getTemplates();
  },
  computed: {
    ...mapGetters({
      accountingTemplates: "accountingStore/templates",
    }),
  },
  methods: {
    ...mapActions({
      getTemplates: "accountingStore/getTemplates",
      deleteTemplate: "accountingStore/deleteTemplate",
      duplicateTemplate: "accountingStore/duplicateTemplate",
    }),
    editItem(item) {
      EventBus.$emit("HB-Navigation:AccountingTemplates", item);
    },
    confirmDeleteItem(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    async deleteItem() {
      try {
        await this.deleteTemplate({
          id: this.selectedItem.id,
        });
        this.dialog = false;
        this.showMessageNotification({
          type: "success",
          description: "Template Deleted Successfully",
        });
      } catch (error) {
        this.dialog = false;
        this.showMessageNotification({
          description: error,
        });
      }
    },
    async duplicateItem(item) {
      try {
        await this.duplicateTemplate({
          id: item.id,
        });
        this.dialog = false;
        this.showMessageNotification({
          type: "success",
          description: "Template Duplicated Successfully",
        });
      } catch (error) {
        this.showMessageNotification({
          description: error,
        });
      }
    },
    addNewTemplate() {
      EventBus.$emit("HB-Navigation:AccountingTemplates");
    },
  },
};
</script>

<style lang="scss" scoped></style>
